import {reactive} from "vue";
import programModel from "@/api/saas/app";

export function useOfficialDefaultDomain(){
    let oddState = reactive({
        programInfo:{},
    })
    function getDefaultData(){
        programModel.getProgramUrl(res=> oddState.programInfo = res)
    }

    return { oddState,getDefaultData }
}
