<template>
	<!-- 微信公众号相关配置 -->
	<div class="setWechat el-content">
		<a-form :label-col="{span:3}" :wrapper-col="{span:12}">
			<a-tabs defaultActiveKey @change="getSetData">
				<a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.tab">
					<div v-if="item.key== 1">
						<a-card title="第三方授权" class="mb12" v-if="wx_use_open == 1">
							<a-form-item label="第三方授权" v-if="!wx_account">
								<a-button type="primary" @click="getAuthUrl">获取授权</a-button>
							</a-form-item>
							<a-form-item label="授权信息" v-if="wx_account">
								<div class="sw-account-card">
									<img :src="wx_account.account.authorizer_info.head_img" alt="">
									<div class="sw-account-card-right">
										<div class="sw-acr-name">{{wx_account.account.authorizer_info.nick_name}}</div>
										<div>主体名称：{{wx_account.account.authorizer_info.principal_name}}</div>
										<div>公众号APPID：{{wx_account.app_id}}</div>
										<div>公众号原始ID：{{wx_account.account.authorizer_info.user_name}}</div>
										<a-button class="sw-acr-btn" type="primary" @click="getAuthUrl">重新授权</a-button>
									</div>
								</div>
							</a-form-item>
						</a-card>

						<com-power-form form-type="card" :form-data="[
							{title:'微信公众号（非服务商）',param:[
								{label:'身份标识(appId)',type:'text',value:'',key:'wx_appid',dataType:'string'},
								{label:'身份密钥(appSecret)',type:'hide-text',value:'',key:'wx_appsecert',dataType:'string'},
							]},
							{title:'微信公众号（服务商）',param:[
								{label:'商户证书序列号',type:'text',value:'',key:'wx_mch_cert_num',dataType:'string'},
								{label:'V3API秘钥',type:'text',value:'',key:'wx_v3vip_key',dataType:'string'},
								{label:'商户私钥',type:'upload-cert',value:'',key:'wx_mch_key',dataType:'string'},
							]}
						]"></com-power-form>
					</div>
					<div v-if="item.key== 2">
						<com-power-form :form-data="[
							{label:'微信支付商户号',type:'text',value:'',key:'wx_mchid',dataType:'string'},
							{label:'微信支付密钥',type:'hide-text',value:'',key:'wx_paysecert',dataType:'string'},
							{label:'微信支付证书CERT',type:'upload-cert',value:'',key:'wx_cert',dataType:'string'},
							{label:'微信支付证书KEY',type:'upload-cert',value:'',key:'wx_key',dataType:'string'},
						]"></com-power-form>
					</div>
					<div v-if="item.key== 4">
						<div class="tips">
							<p>1、微信公众号推送是给商家推送信息，被推送的微信号必须先关注微信公众号才能接受通知！</p>
							<p>2、温馨提示：获取前请先确认您已获得模板消息的使用权限，并且模板消息中没有任何数据。
								获取后请不要到公众号后台 删除相应的模板消息，否则会影响模板消息正常使用。</p>
						</div>
						<a-form-item :wrapper-col="{offset:3}">
							<a-button type="primary" @click="batchSetTemplate">一键设置模板消息</a-button>
						</a-form-item>
						<com-power-form :form-data="[
							{label:'新订单通知',type:'text-btn',value:'',key:'wx_template_id',dataType:'string',
								tipsImg:'admin/wx_template_id/wx-new-order.png'
							},
							{label:'门店通知',type:'text-btn',value:'',key:'wx_outlet_template_id',dataType:'string',
								tipsImg:'admin/wx_template_id/wx-outlet.png'
							},
							{label:'服务申请通知',type:'text-btn',value:'',key:'wx_service_apply_template_id',dataType:'string',
								tipsImg:'admin/wx_template_id/wx-service-apply.png'
							},
							{label:'配送订单通知',type:'text-btn',value:'',key:'wx_deliveryman_template_id',dataType:'string',
								tipsImg:'admin/wx_template_id/wx-send.png'
							},
							{label:'IM消息通知',type:'text-btn',value:'',key:'wx_im_template_id',dataType:'string',
								tipsImg:'admin/wx_template_id/wx-im.png'
							},
						]" @btnClick="showTemplateTest"></com-power-form>
					</div>
					<div v-if="item.key== 5">
						<a-form-item label="管理员绑定公众号">
							<a-button @click="bindOpenIdByAdmin"> 获取二维码</a-button>
							<div v-if="show.notice_auto_qrcode">
								<img :src="show.notice_auto_qrcode" style="width: 100px;height: 100px;" alt="">
							</div>
						</a-form-item>
						<a-form-item label="扫码获取微信推送的openid">
							<a-button @click="getQrcode"> 获取二维码</a-button>
							<div v-if="show.wx_qrcode">
								<img :src="show.wx_qrcode" style="width: 100px;height: 100px;" alt="">
							</div>
						</a-form-item>
						<a-form-item label="接收微信推送的openid">
							<a-textarea v-model:value="manageForm.wx_openids" :rows="6" />
						</a-form-item>
						<a-form-item label="土地通知人员">
							<a-select v-model:value="manageForm.wx_notice_land" placeholder="请选择员工" mode="multiple">
								<a-select-option v-for="item in serviceUser" :key="item.user_id" :value="item.user_id">
									{{item.name}}
								</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="认养通知人员">
							<a-select v-model:value="manageForm.wx_notice_adopt" placeholder="请选择员工" mode="multiple">
								<a-select-option v-for="item in serviceUser" :key="item.user_id" :value="''+item.user_id">
									{{item.name}}
								</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="商城通知人员">
							<a-select v-model:value="manageForm.wx_notice_shop" placeholder="请选择员工" mode="multiple">
								<a-select-option v-for="item in serviceUser" :key="item.user_id" :value="''+item.user_id">
									{{item.name}}
								</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="多商户通知人员">
							<a-select v-model:value="manageForm.wx_notice_store" placeholder="请选择员工" mode="multiple">
								<a-select-option v-for="item in serviceUser" :key="item.user_id" :value="''+item.user_id">
									{{item.name}}
								</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="活动通知人员">
							<a-select v-model:value="manageForm.wx_notice_event" placeholder="请选择员工" mode="multiple">
								<a-select-option v-for="item in serviceUser" :key="item.user_id" :value="''+item.user_id">
									{{item.name}}
								</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item :wrapper-col="{offset:3}">
							<a-button type="primary" @click="submitSave">保存</a-button>
						</a-form-item>
					</div>
					<div v-if="item.key== 3">
                        <a-form-item label="默认域名" v-if="$store.state.modulesPerm.sass == 1" >
                            <a-input v-model:value="oddState.programInfo.app_url" style="width: 400px;" disabled>
                                <template #addonAfter>
                                    <com-copy :custom="true" :value="oddState.programInfo.app_url">复制</com-copy>
                                </template>
                            </a-input>
                            <a-popover placement="bottom">
                                <template #content>
                                    <a-image style="width: 120px"
                                             :src="oddState.programInfo.app_qr_code">
                                    </a-image>
                                </template>
                                <i class="ri-qr-code-line"></i>
                            </a-popover>
                        </a-form-item>
						<a-form-item label="H5域名审核">
							<a-input :disable="h5Form.diabled" v-model:value="h5Form.domain_apply" >
                                <template #addonBefore>
                                    http:// 或者 https:// :
                                </template>
                            </a-input>
							<div v-if="h5Form.audit != -2">
								<p class="f12 lh16" style="color: #66b1ff;" v-if="h5Form.audit == 0 || h5Form.audit == 3 ">
                                    提示：您的域名正在审核中！
                                </p>
								<p class="f12 lh16" style="color: red;" v-if="h5Form.audit == 2">拒绝原因：{{h5Form.reason}}</p>
								<p class="f12 lh16" style="color: #85CE61;" v-if="h5Form.audit == 1">
                                    提示：您的域名审核成功！(请勿重复提交)
                                </p>
							</div>
							<div class="f12">注意：免费版暂时不支持自定义域名，如有需求，请升级付费版！</div>
							<div class="f12 lh16" v-if="$store.state.modulesPerm.sass == 1">
                                自定义域名说明：自定义域名解析例如： “ www.demofarm.com ” 解析到 “{{oddState.programInfo.app_url}}”
                            </div>
						</a-form-item>
					</div>
				</a-tab-pane>
			</a-tabs>
			<a-form-item :wrapper-col="{offset:3}">
				<span v-if="active == 3  && (h5Form.audit == -2 || h5Form.audit == 2 || h5Form.audit == 1)">
					<a-button type="primary" @click="submitSave">提交</a-button>
				</span>
			</a-form-item>
		</a-form>
		<a-modal v-model:visible="show.template_test"
            title="模板消息示例"
            :footer="null"
            @cancel="show.template_test = false"
            width="500px">
            <template v-if="active == 'official'">
				<p>1.登录微信公众号平台，打开-模板消息-模板库</p>
				<p>2.搜索模板“{{show.template_title}}”并添加</p>
				<p>3.模板格式如图</p>
			</template>
			<template v-if="active == 'wxapp'">
				<p>1.进入小程序官方后台，找到订阅消息</p>
				<p>2.查找指定订阅消息并选用</p>
				<p>3.选择如下图的关键词，并按下图调好顺序，点击提交</p>
			</template>
			<div style="width: 100;height: 400px;overflow: hidden;overflow-y: auto;">
				<a-image
				   style="width: 100%;"
				   :src="$util.getStaticSrc(show.template_img)" />
			</div>
        </a-modal>
	</div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
import set from "@/api/set.js"
import user from "@/api/user.js"
import { useRoute } from 'vue-router'
import rh from '@/router/routerHandle.js'
import comPowerForm from '@/components/form/com-power-form.vue'
import { useOfficialDefaultDomain } from '@/models/set'
import store from "@/store";
import comCopy from '@/components/public/com-copy'
export default{
	components:{
		comPowerForm,
        comCopy
	},
	setup(){
		const active = ref("1")
		const _d = reactive({
			tabs:[],
			wx_account:null,	//微信公众号授权信息
			wx_use_open:'0',
			h5Form:{
                domain_apply: '',
                audit: -2,
				reason:"",
				diabled:false
            },
			manageForm:{
				wx_openids:"",
				wx_notice_land:[],
				wx_notice_adopt:[],
				wx_notice_shop:[],
				wx_notice_store:[],
				wx_notice_event:[],
			},
			show:{
				template_test:false,
				template_title:"",
				template_img:"",
				wx_qrcode:"",
				notice_auto_qrcode:"",
			},
			serviceUser:[],
		})

		const options = useRoute().query
        //是否存在授权码，存在重新设置授权信息
        if(options.auth_code){
            setOpenAuth(options.auth_code)
        }
		_d.tabs = rh.getCuurentTabs("wxset")
        if( _d.tabs.length > 0 ) getSetData(_d.tabs[0].key)
		set.getProgramInfo().then(res=>{
			_d.wx_use_open = res.config.wx_use_open || '0';
			getOpenAuth('official');
		})

        //域名设置默认域名展示
        let {oddState ,getDefaultData } = useOfficialDefaultDomain()

        function getSetData(_a){
            let  keys = []
			active.value = _a
            if( _a == 1 ) {
				 if(_d.wx_use_open == 1 ){
					getOpenAuth("official");
				}
				return
			}
            if( _a == 3 ) {
                if( store.state.modulesPerm.sass == 1 ){
                    getDefaultData()
                }

				set.handleAPPSite("get").then(res=>{
					if(!res ) return;
					_d.h5Form.domain_apply = res.domain_apply
					_d.h5Form.audit = res.audit
					_d.h5Form.reason = res.reason
					_d.h5Form.diabled = res.diabled
				})
				return
			}
			if( _a == 5 ){
				keys = Object.keys(_d.manageForm)
				user.getEmployeeList().then(res=>{
					_d.serviceUser = res
				})
			}

            set.getFarmSet(keys).then(res=>{
				if( _a == 3 ) _d.h5Form = { ...res}
				if( _a == 5 ) {
					_d.manageForm.wx_openids = res.wx_openids
					_d.manageForm.wx_notice_land = res.wx_notice_land ? JSON.parse(res.wx_notice_land) :[]
					_d.manageForm.wx_notice_adopt = res.wx_notice_adopt ? JSON.parse(res.wx_notice_adopt) :[]
					_d.manageForm.wx_notice_shop = res.wx_notice_shop ? JSON.parse(res.wx_notice_shop) :[]
					_d.manageForm.wx_notice_store = res.wx_notice_store ? JSON.parse(res.wx_notice_store) :[]
					_d.manageForm.wx_notice_event = res.wx_notice_event ? JSON.parse(res.wx_notice_event) :[]
				}
            })
        }

        function submitSave(){
            let _a = active.value , param = null
            if( _a == 1 ) param = _d.wxappForm
            if( _a == 2 ) param = _d.payForm
            if( _a == 4 ) param = _d.official
            if( _a == 5 ) param = _d.manageForm
			if( _a == 3 ){
				set.handleAPPSite("edit",{domain_apply: _d.h5Form.domain_apply}).then(()=>{
					getSetData();
					//set.setFarmSet({app_domain:_d.h5Form.domain_apply})
				})
				return
			}
            set.setFarmSet(param)
        }

		function getOpenAuth(auth_type){
            set.getWxOpenAuth(auth_type).then(res=>{
                _d.account = res
            })
        }

		function getAuthUrl(){
            set.getWxOpenAuthUrl().then(res=>{
                location.href = res
            })
        }

		function batchSetTemplate(){
			set.batchSetSubscribeMsg('wx').then(()=>{
                getSetData()
            })
		}
		function showTemplateTest(e){
			_d.show.template_title = e.title
			_d.show.template_img = e.img
			_d.show.template_test = true
		}

		function bindOpenIdByAdmin(){
			set.getBindWxAdminQrcode().then(res=>{
				_d.show.notice_auto_qrcode = res
			})
		}

		function getQrcode(){
			set.getWxOpenIdQrcode().then(res=>{
				_d.show.wx_qrcode = res
			})
		}

		return{
			active,
			...toRefs(_d),
			submitSave,
			getAuthUrl,
			getSetData,
			batchSetTemplate,
			showTemplateTest,
			bindOpenIdByAdmin,
			getQrcode,

            oddState
		}
	}
}
</script>

<style lang="scss">
	.sw-account-card{
		width: 500px;
		height: 180px;
		border:1px solid #F0F0F0;
		display: flex;
		align-items: center;
		border-radius: 8px;
		box-shadow: 1px 1px 5px #e2e2e2;

		img{
			width: 100px;
			height: 100px;
			border-radius: 50%;
			margin-right: 16px;
		}

		&-right{
			line-height: 24px;
			font-size: 15px;
			color: #666;

			.sw-acr-name{
				font-weight: bold;
			}
			.sw-acr-btn{
				margin-top: 8px;
			}
		}
	}
    .setWechat{
        .ri-qr-code-line{
            font-size: 30px;
            position: absolute;
            margin-left: 12px;
            cursor: pointer;
            color: #444141;
        }
    }

</style>
